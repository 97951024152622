import keyMirror from 'keymirror';
import { images } from 'Constants';
import { Language } from './types';
import { Flags } from 'types/LaunchDarkly';

export enum Languages {
    EN = 'en',
    DA = 'da',
    NO = 'no',
    SV = 'sv',
    NL = 'nl',
    FR = 'fr',
    FI = 'fi',
    DE = 'de',
}

export const LanguagesWithEnglishMarketing = new Set<Languages>([
    Languages.EN,
    Languages.FI,
    Languages.DE,
]);

export const LANGUAGES: { [name in Languages]: Language } = {
    en: {
        id: Languages.EN,
        name: 'English (UK)',
        flag: `${images}/flags/gb.gif`,
        svgFlag: `${images}/flags/gb.svg`,
    },
    da: {
        id: Languages.DA,
        name: 'Dansk',
        flag: `${images}/flags/dk.gif`,
        svgFlag: `${images}/flags/dk.svg`,
    },
    sv: {
        id: Languages.SV,
        name: 'Svenska',
        flag: `${images}/flags/se.gif`,
        svgFlag: `${images}/flags/se.svg`,
    },
    no: {
        id: Languages.NO,
        name: 'Norsk',
        flag: `${images}/flags/no.gif`,
        svgFlag: `${images}/flags/no.svg`,
    },
    nl: {
        id: Languages.NL,
        name: 'Nederlands',
        flag: `${images}/flags/nl.gif`,
        svgFlag: `${images}/flags/nl.svg`,
    },
    fr: {
        id: Languages.FR,
        name: 'Français',
        flag: `${images}/flags/fr.gif`,
        svgFlag: `${images}/flags/fr.svg`,
    },
    fi: {
        id: Languages.FI,
        name: 'Suomi',
        flag: `${images}/flags/fi.gif`,
        svgFlag: `${images}/flags/fi.svg`,
        availabilityFlag: Flags.FINNISH_LANGUAGE_AVAILABLE,
    },
    de: {
        id: Languages.DE,
        name: 'Deutsch',
        flag: `${images}/flags/de.gif`,
        svgFlag: `${images}/flags/de.svg`,
    },
};

export const ZENDESK_LANGUAGE_MAP = {
    [Languages.EN]: 'en-gb',
    [Languages.DA]: 'da',
    [Languages.NO]: 'no',
    [Languages.SV]: 'sv',
    [Languages.NL]: 'nl',
    [Languages.FR]: 'fr',
    [Languages.FI]: 'fi',
    [Languages.DE]: 'de',
};

export const DEFAULT_LANGUAGE = Languages.EN;
export const ActionTypes = keyMirror({
    LANGUAGE_CHANGE: null,
});
