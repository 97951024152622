import React, { useEffect, useState } from 'react';
import {
    signatureLineStatus,
    SigningProcess,
    SigningProcessError,
    signingRequestStatus,
} from '../../types/SigningProcess';
import { NewSignerAPI, PublicAuthAPI } from '../../Api';
import {
    getSignerLanguage,
    setSignerLanguage,
    signingBranding,
    V2Validation,
} from '../../Signing/utils';
import { i18n, TranslationActions } from '../../Language';
import { Languages } from '../../Language/Constants';
import NAPSigningMethods from './NAPSigningMethods';
import Banner from 'Common/components/Banner';
import {
    clearOpenIdState,
    getOpenIdState,
} from '../../OpenID/utils/openIdState';
import Button from '../../Common/components/Button';
import analytics from '../../Common/Analytics';
import { getUrlId } from '../../OpenID/redux/actions';
import { Intent } from '../../OpenID/Constants';
import { OpenIdLocalStorageState } from '../../OpenID/redux/types';
import { ALLOWED_NAP_SIGNING_METHODS } from 'EID/Constants';

import { CustomerSigningMethod } from '../../types/Customer';
import { Header } from 'Common/components/HeaderServices/Header';
export type Props = {
    params: {
        challengeKey: string;
    };
};

export default function MitIDNAPInterventionScreen({
    params: { challengeKey },
}: Props) {
    const [encryptedNIN, setEncryptedNIN] = useState<string | undefined>();
    const [casefileData, setCasefileData] = useState<SigningProcess>();
    const [showValidationError, setShowValidationError] =
        useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);

    // This is only set to force a re-render after the user selects a language.
    const [_, setUserSelectedLanguage] = useState(''); // eslint-disable-line

    useEffect(() => {
        (async () => {
            if (casefileData) {
                return;
            }

            try {
                const caseData: SigningProcess = await NewSignerAPI.get(
                    `/v2/signing-process/${challengeKey}`
                );

                setCasefileData(caseData);
                setEncryptedNIN(caseData.encryptedNIN);
            } catch (error) {
                console.error('Failed fetching case file data', error);

                if (error?.status === 410) {
                    // typing
                    const signingDoneData: SigningProcessError = error;
                    /**
                     * Both the casefile or the current signature line could have expired,
                     * that's why we have to check for those two scenarios.
                     * In these cases, signingRequestStatus will be REJECTED,
                     * which is not giving the real error we need to show.
                     */
                    const currentRequestStatus =
                        signingDoneData?.data?.errors.caseFileExpired ||
                        signingDoneData?.data?.errors.signatureLineStatus ===
                            signatureLineStatus.SIGNATURE_LINE_EXPIRED
                            ? signingRequestStatus.expired
                            : signingDoneData?.data?.errors
                                  .signingRequestStatus;

                    // Redirect to signed/rejected or other custom error page
                    return window.location.assign(
                        `${window.location.origin}/signing/${challengeKey}/${currentRequestStatus}`
                    );
                }

                if (error?.status === 403) {
                    window.location.assign(
                        `${window.location.origin}/signing/validation/${challengeKey}?validate_error=true`
                    );
                }
            }

            const openIdState = getOpenIdState();

            if (openIdState) {
                await loadValidationToken(openIdState);
            }

            setIsLoading(false);
        })();
    });

    useEffect(() => {
        (async () => {
            if (!casefileData) {
                return;
            }

            const language =
                getSignerLanguage() ?? casefileData.caseFile.language;

            await changeLanguage(language);
        })();
    }, [casefileData]);

    async function loadValidationToken(
        openIdState: OpenIdLocalStorageState
    ): Promise<void> {
        if (V2Validation.get()) {
            return;
        }

        try {
            const { validation_token } = await PublicAuthAPI.post(
                `/openid/${getUrlId(
                    openIdState.serviceId,
                    Intent.SIGN
                )}/validation-token`,
                {
                    idToken: openIdState.openIdToken,
                    options: {
                        encryptedNIN: openIdState.encryptedNIN,
                    },
                }
            );

            V2Validation.set(validation_token);
            setEncryptedNIN(openIdState.encryptedNIN);
            clearOpenIdState();
        } catch (e) {
            console.error('Error fetching validation token', e);

            analytics.track('NAP detected', {
                validationError: true,
            });

            V2Validation.clear();
            setShowValidationError(true);
        }
    }

    async function changeLanguage(languageCode: Languages) {
        await TranslationActions.changeLanguageView(languageCode);

        setSignerLanguage(languageCode);

        setUserSelectedLanguage(languageCode);
    }

    const handleBackClick = () => {
        clearOpenIdState();
        window.location.href = `/signing/${challengeKey}`;
    };

    if (!casefileData || !challengeKey || isLoading) {
        return null;
    }

    const { customer, signingRequest, allowedSigningMethods } = casefileData;
    const branding = signingBranding(customer.branding);

    const signingMethods: CustomerSigningMethod[] = ALLOWED_NAP_SIGNING_METHODS
        // eslint-disable-next-line
        .map((m) => m.credentialType() as CustomerSigningMethod)
        .filter((m) => allowedSigningMethods.includes(m))
        .concat(customer.allowedSimpleSigningMethods);

    return (
        <div className="flex flex-col items-center">
            <Header
                branding={branding}
                showSelectLanguage={true}
                onSelectLanguageClick={changeLanguage}
            />

            <Banner
                variant="danger"
                content={i18n(
                    'A digital signature with MitID will not be able to confirm your name'
                )}
                icon={
                    <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                    />
                }
            />

            <div className="max-w-lg mt-20 sm:mt-12 px-6 sm:px-0">
                <div className="space-y-8">
                    <h1 className="font-bold sm:text-2xl">{i18n`The documents have not been signed`}</h1>
                    {showValidationError ? (
                        <>
                            <p>
                                {i18n(
                                    "It looks like you don't have access. You can contact the sender in case you think this is a mistake."
                                )}
                            </p>

                            <Button
                                className="mt-5"
                                theme="blue"
                                variant="solid"
                                onClick={handleBackClick}>
                                {i18n('Return to documents')}
                            </Button>
                        </>
                    ) : (
                        <NAPSigningMethods
                            challengeKey={challengeKey}
                            customerAllowedSigningMethods={signingMethods}
                            encryptedNIN={encryptedNIN}
                            signingRequest={signingRequest}
                            allowSigningWithNap={customer.allowSigningWithNap}
                        />
                    )}
                </div>
            </div>
            <div
                className="signing-footer"
                style={{ backgroundColor: branding.backgroundColor }}
            />
        </div>
    );
}
