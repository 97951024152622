import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PublicAuthAPI, PublicSessionAPI } from 'Api';
import { signingData, signingRedirectUrl, trackSigning } from 'Signing/utils';
import { AppThunk } from 'Store';
import { signingStages } from 'types/SigningProcess';
import { SMSError } from '../types';
import { TranslationStore } from 'Language';

type State = {
    codeSent: boolean;
    showConfirmModal: boolean;
    challengeKey?: string;
    error?: SMSError & Error;
};

const SMSValidationSlice = createSlice({
    name: 'SMSValidation',
    initialState: {
        codeSent: false,
    } as State,
    reducers: {
        setError: (state, action: PayloadAction<SMSError & Error>) => {
            state.error = action.payload;
        },
        setCodeSent: (state, action: PayloadAction<boolean>) => {
            state.codeSent = action.payload;
        },
        setChallengeKey: (state, action: PayloadAction<string>) => {
            state.challengeKey = action.payload;
        },
        setShowConfirmModal: (state, action: PayloadAction<boolean>) => {
            state.showConfirmModal = action.payload;
        },
    },
});

export const sendSMS =
    (challengeKey: string): AppThunk =>
    async (dispatch) => {
        const language = TranslationStore.getLanguage();

        try {
            await PublicAuthAPI.post('/sms/send', { challengeKey, language });

            dispatch(setCodeSent(true));
        } catch (error) {
            dispatch(setError(error));
        }
    };

export const validateOTP = async (challengeKey: string, code: string) => {
    try {
        const response = await PublicAuthAPI.post('/sms/validate', {
            challengeKey,
            code,
        });

        return response;
    } catch (error) {
        setError(error);
    }
};

export const rejectSigning = async (challengeKey: string) => {
    try {
        const rejectResult = await PublicSessionAPI.post(
            `/casefile/action/reject/${challengeKey}`,
            {
                reason: 'The recipient has reported that the phone number configured for Viewing Restrictions with SMS is not theirs. Therefore they cannot access the documents, and the signature has been marked as declined. Please create a new casefile and confirm the phone number of the intended signer.',
            }
        );
        const localData = signingData.get(challengeKey);

        if (localData) {
            signingData.set(challengeKey, {
                ...localData,
                ...{ outcome: signingStages.rejected },
            });
        }

        const defaultRedirectUrl = await signingRedirectUrl(
            signingStages.rejected,
            challengeKey,
            true
        );

        window.location.href = rejectResult?.redirectUrl ?? defaultRedirectUrl;
    } catch (error) {
        setError(error);
        trackSigning('Signing Reject Modal - Reject fail');
    }
};

export const { setError, setCodeSent, setChallengeKey, setShowConfirmModal } =
    SMSValidationSlice.actions;
export default SMSValidationSlice.reducer;
