import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import UIInputText from 'Common/components/Vega/InputText';
import UILozenge from 'Common/components/Vega/Lozenge';
import { images } from 'Constants';
import { i18n } from 'Language';
import React, { useEffect } from 'react';
import { useIdentityCheck } from '../Hooks/useIdentityCheck';
import { Signature } from '../Models/ValidatorResponse';
import {
    signatureTypeName,
    unixTimeToUTC,
    validationTypeText,
} from '../Utils/Utils';
import '../Styles/styles.css';
import { Button } from '@penneo/vega-ui-react';
import { useValidator } from '../Store/ValidatorContext';

const SignatureSection = ({
    signature,
    index,
}: {
    signature: Signature;
    index: number;
}) => {
    const {
        identityCheckValue,
        match,
        checkedIdentity,
        setValue,
        checkIdentity,
        status,
    } = useIdentityCheck(signature);

    const { trackAction } = useValidator();

    useEffect(() => {
        if (status === 'success') {
            trackAction('validator-service - ssn check successful');
        }

        if (status === 'failure') {
            trackAction('validator-service - ssn check failed');
        }
    }, [status, trackAction]);

    const showIdentityCheck =
        signature.signatureValid &&
        ((signature.type === 'penneo' &&
            signature.validations.length > 0 &&
            signature.validations.includes('ssn')) ||
            signature.type === 'bankid_se');

    return (
        <section
            aria-label={
                i18n`validatorDetailsForSignature` + ` ` + `${index + 1}`
            }
            className="flex flex-col bg-gray-50 gap-4 p-6 w-full">
            <div className="h-6 justify-center items-center gap-2 inline-flex">
                <img
                    className="new-version-modal-header-icon"
                    src={`${images}/icon_marketing-sign.svg`}
                    alt={i18n`iconMarketingSignAltText`}
                />
                <h4 className="grow shrink basis-0 text-gray-900 text-sm font-semibold leading-tight">
                    Signature {index + 1}
                </h4>
            </div>
            <div className="flex flex-col gap-4">
                <dl className="grid grid-cols-1 sm:grid-cols-[max-content,1fr] gap-y-1 gap-x-6">
                    <dt className="label">{i18n`Name`}:</dt>
                    <dd className="value">{signature.name}</dd>
                    <dt className="label">{i18n`Roles`}:</dt>
                    <dd className="value">
                        {signature.signatureLineInfo
                            .map((line) => line.role)
                            .join(', ')}
                    </dd>
                    {signature.validations.length > 0 && (
                        <>
                            <dt className="label">
                                {i18n`validatorValidation`}:
                            </dt>
                            <dd className="value">
                                {signature.validations
                                    .map((val) => validationTypeText(val))
                                    .join(', ')}
                            </dd>
                        </>
                    )}
                </dl>
                <dl className="grid grid-cols-1 sm:grid-cols-[max-content,1fr] gap-y-1 gap-x-6">
                    <dt className="label">{i18n`validatorSignatureType`}:</dt>
                    <dd className="value">
                        {signatureTypeName(signature.type)}
                    </dd>
                    <dt className="label">{i18n`validatorSerialNumber`}:</dt>
                    <dd className="value break-words">
                        {signature.serialNumber}
                    </dd>
                </dl>
                <dl className="grid grid-cols-1 sm:grid-cols-[max-content,1fr] gap-y-1 gap-x-6">
                    <dt className="label">{i18n`validatorTimeOfSigning`}:</dt>
                    <dd className="value">
                        {unixTimeToUTC(signature.signTime)}
                    </dd>
                </dl>
                <dl className="grid grid-cols-1 sm:grid-cols-[max-content,1fr] gap-y-1 gap-x-6">
                    <dt className="label">{i18n`Status`}:</dt>
                    <dd className="value">
                        <div className="flex flex-col gap-1">
                            {signature.signatureValid ? (
                                <div className="flex flex-col gap-1">
                                    <UILozenge type="solid" variant="success">
                                        <CheckCircleIcon className="w-4 align-middle inline-block shrink-0" />
                                        <span className="ml-2">
                                            {i18n`validatorSignatureIsValid`}
                                        </span>
                                    </UILozenge>
                                </div>
                            ) : (
                                <div className="flex flex-col gap-1">
                                    <UILozenge type="solid" variant="danger">
                                        <XCircleIcon className="w-4 align-middle inline-block" />
                                        <span className="ml-2">
                                            {i18n`validatorSignatureIsNotValid`}
                                        </span>
                                    </UILozenge>
                                </div>
                            )}
                            {signature.signatureMatchDocument ? (
                                <div className="flex flex-col gap-1">
                                    <UILozenge type="solid" variant="success">
                                        <CheckCircleIcon className="w-4 align-middle inline-block shrink-0" />
                                        <span className="ml-2">
                                            {i18n`validatorSignatureBelongsToDocument`}
                                        </span>
                                    </UILozenge>
                                </div>
                            ) : (
                                <div className="flex flex-col gap-1">
                                    <UILozenge type="solid" variant="danger">
                                        <XCircleIcon className="w-4 align-middle inline-block" />
                                        <span className="ml-2">
                                            {i18n`validatorSignatureDoesNotBelongToDocument`}
                                        </span>
                                    </UILozenge>
                                </div>
                            )}
                        </div>
                    </dd>
                </dl>
                {showIdentityCheck && (
                    <dl className="grid grid-cols-1 sm:grid-cols-[max-content,1fr] gap-y-1 gap-x-6">
                        <dt className="label">
                            {i18n`validatorCheckSignerIdentity`}:
                        </dt>
                        <dd className="max-w-80 grid grid-cols-1 sm:grid-cols-[max-content,1fr] gap-y-1 gap-x-2">
                            <UIInputText
                                attachedLabel="SSN"
                                className="w-full sm:w-80"
                                inputValue={identityCheckValue}
                                error={status === 'failure'}
                                errorText={i18n`validatorValueDoesNotMatch`}
                                success={status === 'success'}
                                successText={i18n`validatorValueMatches`}
                                information={
                                    false ===
                                    ['success', 'failure'].includes(status)
                                }
                                informationText={i18n`validatorSSNInformationText`}
                                onInput={(_, { value }) => {
                                    const trimmed = value.slice(0, 15);

                                    setValue(trimmed);
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        checkIdentity();
                                    }
                                }}></UIInputText>
                            <Button
                                priority="primary"
                                onClick={checkIdentity}
                                isDisabled={
                                    checkedIdentity || status === 'idle'
                                }
                                isLoading={status === 'checking'}
                                size="sm">{i18n`Check`}</Button>
                            <div
                                role="status"
                                aria-live="polite"
                                className="sr-only">
                                {checkedIdentity &&
                                    (match
                                        ? i18n`validatorValueMatches`
                                        : i18n`validatorValueDoesNotMatch`)}
                            </div>
                        </dd>
                    </dl>
                )}
            </div>
        </section>
    );
};

export default SignatureSection;
