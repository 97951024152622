//import { PublicSignerAPI } from 'Api';
import { i18n, TranslationActions } from 'Language';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

/* new signing components */
import {
    trackSigning,
    signingData,
    signingRedirectUrl,
    signingBranding,
    setSignerLanguage,
} from '../utils';
import { Languages } from 'Language/Constants';

import Loader from 'Common/components/Common/Loader';
import './signing-done.scss';
import { signingStages } from 'types/SigningProcess';
import { LandingPageLayout } from './Common/LandingPageLayout';
import { InfoSection } from './Common/InfoSection';
import { Header } from 'Common/components/HeaderServices/Header';

const SigningFail = ({ challengeKey }: { challengeKey: string }) => {
    const [determined, setDetermined] = useState(false);
    // This is only set to force a re-render after the user selects a language.
    const [userSelectedLanguage, setUserSelectedLanguage] = useState<Languages>(
        Languages.EN
    ); // eslint-disable-line

    useEffect(() => {
        (async () => {
            // makes sure to cleanup after signing
            const localData = signingData.get(challengeKey);
            const outcome = localData?.outcome;

            if (outcome) {
                const signRedirectUrl = await signingRedirectUrl(
                    outcome as signingStages,
                    challengeKey
                );

                setDetermined(true);
                window.location.replace(signRedirectUrl);
            }
        })();
    }, [challengeKey]);

    async function changeLanguage(languageCode: Languages) {
        await TranslationActions.changeLanguageView(languageCode);

        setSignerLanguage(languageCode);

        setUserSelectedLanguage(languageCode);
    }

    const linkTracking = (name: string, url: string) =>
        trackSigning(`Fail sign page - Link clicked`, { name, url });

    const linkLocalization = (language: Languages = Languages.EN) =>
        language === Languages.EN ? '' : `${language}/`;

    const links = [
        {
            name: 'Legality of digital signatures',
            url: `blog/digital-signatures/`,
        },
        {
            name: 'Validity of the signed document',
            url: `trust-center`,
        },
        {
            name: 'EU & international regulations',
            url: `blog/eidas-regulation/`,
        },
        {
            name: 'Penneo for businesses',
            url: `free-trial-businesses/`,
        },
    ];

    if (determined) {
        return (
            <>
                <br />
                <br />
                <Loader />
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>{i18n`Something went wrong`}</title>
            </Helmet>

            <Header
                branding={signingBranding()}
                onSelectLanguageClick={changeLanguage}
                showSelectLanguage={true}
            />
            <LandingPageLayout
                linkTracking={linkTracking}
                links={links}
                language={userSelectedLanguage}
                linkLocalization={linkLocalization}>
                <InfoSection
                    title={i18n`signFail.title`}
                    description={i18n`signFail.deleted.description`}
                    info={i18n`signFail.deleted.disclaimer`}
                />
            </LandingPageLayout>
        </>
    );
};

export default SigningFail;
