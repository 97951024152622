import React, { useContext } from 'react';
import Constants from 'Constants';
import { SignerEntity } from 'types/Signer';
import Analytics from 'Common/Analytics';
import { SigningAPI } from 'Api';
import { ToastContext } from 'Common/components/ToastContext';
import { i18n } from 'Language';

export type Props = {
    signer: SignerEntity;
    file: any;
};

const trackDownload = (fileId: number, signerId: number) => {
    Analytics.track('casefile - signer attachment downloaded', {
        fileId,
        signerId,
    });
};

const downloadFile = async (
    signerId: number,
    fileId: number
): Promise<Blob> => {
    const response = await SigningAPI.get(
        `/v3/signers/${signerId}/files/${fileId}/download`,
        null,
        { responseType: 'blob' }
    );

    const blob = response instanceof Blob ? response : new Blob([response]);

    return blob;
};

const CasefileAttachment: React.FC<Props> = ({ signer, file }) => {
    const { addToast } =
        useContext(ToastContext) ??
        (() => {
            throw new Error('ToastContext not found');
        })();

    const handleClick = async (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();

        trackDownload(file.id, signer.id);

        try {
            const blob = await downloadFile(signer.id, file.id);
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');

            link.href = url;

            link.download = file.title || 'download';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setTimeout(() => window.URL.revokeObjectURL(url), 60_000);
        } catch (error) {
            addToast?.(
                i18n`signerFileAttachmentErrorMessage`,
                i18n`toast.generalError.title`,
                'danger',
                10000,
                i18n`toast.cancelButton`
            );
        }
    };

    return (
        <a
            onClick={handleClick}
            href={`${Constants.PENNEO_ORIGIN}/api/v3/signers/${signer.id}/files/${file.id}/download`}
            target="_blank"
            rel="noopener noreferrer">
            <div className="signer-details-file">
                <div className="signer-details-file-container">
                    <div className="signer-details-file-hover-overlay">
                        <div className="signer-details-file-hover-overlay-content">
                            <i className="far fa-file-download" />
                        </div>
                    </div>
                    <div className="signer-details-file-title">
                        {file.title}
                    </div>
                </div>
            </div>
        </a>
    );
};

export default CasefileAttachment;
