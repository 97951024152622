import React, { useMemo } from 'react';
import { i18n, TranslationStore } from 'Language';
import { CustomerBranding } from 'types/SigningProcess';
import { images } from 'Constants';
import { Languages, LANGUAGES } from 'Language/Constants';
import { Button, ButtonProps, ListBox } from '@penneo/vega-ui-react';
import {
    ArrowDownTrayIcon,
    ArrowsPointingOutIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { checkColorSchemeValid } from 'Signing/utils';
import clsx from 'clsx';

type Props = {
    branding?: CustomerBranding;
    defaultLanguage?: Languages;
    onSelectLanguageClick?: (languageCode: Languages) => void;
    onDownloadDocumentClick?: () => void;
    onOpenDocumentClick?: () => void;
    onHelpCenterLinkClick?: () => void;
    showHelpCenter?: boolean;
    showDownloadDocument?: boolean;
    showOpenDocument?: boolean;
    showSelectLanguage?: boolean;
};

type SelectedItem = {
    id: string | number | Languages;
    name: string;
    buttonLabel?: string;
};

type Styling = {
    theme: ButtonProps['theme'];
    headerStyles?: {};
    textColor?: string;
};

export const Header = ({
    onHelpCenterLinkClick,
    defaultLanguage,
    onSelectLanguageClick,
    branding,
    onDownloadDocumentClick,
    onOpenDocumentClick,
    showHelpCenter = false,
    showDownloadDocument = false,
    showOpenDocument = false,
    showSelectLanguage = false,
}: Props) => {
    const companyInfo = {
        website: branding?.siteUrl || 'https://penneo.com',
        logo: branding?.logoUrl || `${images}/penneo.svg`,
        logoAlt: branding ? '' : 'Penneo logo',
        ariaLabel: branding ? `Open website` : 'Open Penneo website',
    };

    const languageItems = Object.values(LANGUAGES).map((language) => ({
        id: language.id,
        name: `${language.name} - ${language.id.toUpperCase()}`,
        buttonLabel: language.id.toUpperCase(),
    }));

    const handleLanguageChange = (item: SelectedItem) => {
        if (onSelectLanguageClick) {
            onSelectLanguageClick(item.id as Languages);
        }
    };

    const { theme, headerStyles, textColor }: Styling = useMemo(() => {
        if (!branding || !checkColorSchemeValid(branding)) {
            return {
                theme: 'dark',
                headerStyles: {},
                textColor: undefined,
            };
        }

        const hex = branding.backgroundColor ?? '#000000';
        const r = parseInt(hex.substr(1, 2), 16);
        const g = parseInt(hex.substr(3, 2), 16);
        const b = parseInt(hex.substr(5, 2), 16);
        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

        return {
            theme: luminance > 120 ? 'light' : 'dark',
            headerStyles: branding.backgroundColor
                ? { backgroundColor: branding.backgroundColor }
                : {},
            textColor: branding.textColor ? `${branding.textColor}` : undefined,
        };
    }, [branding]);

    const currentLanguage = defaultLanguage || TranslationStore.getLanguage();

    const hideIconText = (() => {
        let visibleCount = 0;

        if (showHelpCenter) visibleCount++;

        if (showOpenDocument) visibleCount++;

        if (showDownloadDocument) visibleCount++;

        if (showSelectLanguage) visibleCount++;

        return visibleCount > 1;
    })();

    const textStyle = textColor ? { color: textColor } : undefined;

    return (
        <header className="w-full">
            <div
                style={headerStyles}
                className={clsx(
                    'h-[76px] w-full md:h-16 py-4 xs:px-3 md:p-3 z-10 fixed top-0',
                    !branding && 'bg-primary-900'
                )}>
                <div className="flex flex-wrap justify-between items-center max-w-[1170px] mx-auto max-[1170px]:px-3">
                    <div className="flex flex-wrap justify-between items-center">
                        <a
                            aria-label={i18n(companyInfo.ariaLabel)}
                            href={companyInfo.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="w-32 md:w-36 h-10 flex items-center">
                            <img
                                alt={companyInfo.logoAlt}
                                src={companyInfo.logo}
                                className={clsx(
                                    'my-1 mr-8 h-10',
                                    !branding && 'w-32 md:w-36'
                                )}
                            />
                        </a>
                    </div>

                    <div className="flex flex-wrap justify-between items-center gap-x-1 xs:gap-x-2 md:gap-x-4">
                        {showOpenDocument && onDownloadDocumentClick && (
                            <Button
                                onClick={onDownloadDocumentClick}
                                theme={theme}
                                priority="tertiary"
                                type="neutral"
                                ariaLabel={i18n`Download document`}
                                data-testid="header-link"
                                leftIcon={
                                    <ArrowDownTrayIcon
                                        className="w-6 h-6"
                                        color={textColor}
                                    />
                                }
                                childrenClassName={clsx(
                                    hideIconText && 'hidden md:inline'
                                )}>
                                <span style={textStyle}>{i18n`Download`}</span>
                            </Button>
                        )}
                        {showDownloadDocument && onOpenDocumentClick && (
                            <Button
                                onClick={onOpenDocumentClick}
                                theme={theme}
                                priority="tertiary"
                                type="neutral"
                                role="link"
                                ariaLabel={i18n`Open document in Adobe Acrobat`}
                                data-testid="header-link"
                                leftIcon={
                                    <ArrowsPointingOutIcon
                                        className="w-6 h-6"
                                        color={textColor}
                                    />
                                }
                                childrenClassName={clsx(
                                    hideIconText && 'hidden md:inline'
                                )}>
                                <span
                                    className="underline"
                                    style={textStyle}>{i18n`Open`}</span>
                            </Button>
                        )}
                        {showHelpCenter && onHelpCenterLinkClick && (
                            <Button
                                onClick={onHelpCenterLinkClick}
                                theme={theme}
                                priority="tertiary"
                                type="neutral"
                                role="link"
                                ariaLabel={i18n`Open Help Center link`}
                                data-testid="header-link"
                                leftIcon={
                                    <QuestionMarkCircleIcon
                                        className="w-6 h-6 w-"
                                        color={textColor}
                                    />
                                }
                                childrenClassName={clsx(
                                    hideIconText && 'hidden md:inline'
                                )}>
                                <span className="underline" style={textStyle}>
                                    {i18n`Help Center`}
                                </span>
                            </Button>
                        )}
                        {showSelectLanguage &&
                            onSelectLanguageClick &&
                            currentLanguage && (
                                <ListBox
                                    items={languageItems}
                                    optionsClassName="z-20 w-[248px]"
                                    onChange={handleLanguageChange}
                                    defaultSelectedId={
                                        LANGUAGES[currentLanguage].id
                                    }
                                    anchor="bottom end"
                                    children={({
                                        selectedItem,
                                        defaultRightIcon,
                                    }) => (
                                        <Button
                                            priority="secondary"
                                            type="neutral"
                                            theme={theme}
                                            ariaLabel={i18n`Language selection`}
                                            className="w-[70px] justify-between"
                                            rightIcon={defaultRightIcon}>
                                            <span style={textStyle}>
                                                {selectedItem?.buttonLabel}
                                            </span>
                                        </Button>
                                    )}
                                />
                            )}
                    </div>
                </div>
            </div>
        </header>
    );
};
