import { NewSignerAPI } from 'Api';
import { i18n, TranslationActions } from 'Language';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    signingRequestStatus,
    SigningProcessWithOutcome,
} from 'types/SigningProcess';

import {
    getSignerLanguage,
    isV1Signing,
    isV2Signing,
    setSignerLanguage,
    signingBranding,
    signingData,
    trackSigning,
    V2Validation,
} from '../utils';
import { Languages, LanguagesWithEnglishMarketing } from 'Language/Constants';
import { SigningDoneComponents } from './SigningDoneComponents';
import Loader from 'Common/components/Common/Loader';
import { clearOpenIdState } from '../../OpenID/utils/openIdState';
import { Header } from 'Common/components/HeaderServices/Header';

type Props = {
    params: {
        challengeKey: string;
        requestStatus: signingRequestStatus;
    };
};

const SigningDone = ({ params: { challengeKey, requestStatus } }: Props) => {
    const [page, setPage] = useState<string>();
    const [casefileData, setCasefileData] =
        useState<SigningProcessWithOutcome>();
    const [redirectData, setRedirectData] = useState<{
        successUrl: string;
        failUrl: string;
    } | null>(null);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [translated, setTranslated] = useState(false);
    const [UserSelectedLangauge, setUserSelectedLanguage] =
        useState<Languages>();

    useEffect(() => {
        const requestText =
            requestStatus === signingRequestStatus.signed
                ? 'completed'
                : requestStatus;

        setPage(`Signing ${requestText}`);
    }, [requestStatus]);

    useEffect(() => {
        if (!page) {
            return;
        }

        trackSigning(page);
        // makes sure to cleanup after signing
        isV2Signing.clear();
        clearOpenIdState();
    }, [page]);

    useEffect(() => {
        (async () => {
            try {
                await NewSignerAPI.get(`/v2/signing-process/${challengeKey}`);
            } catch (error) {
                /**
                 * The endpoint ONLY returns an error. If it's 410, the casefile data is readily available
                 * but if it's 403, it means the user needs to validate their ID to access it
                 */

                if (
                    error?.status === 403 &&
                    // do not navigate to validation when phone number rejected
                    error?.data?.expectedEid !== 'sms'
                ) {
                    const validationUrl = `${window.location.origin}/signing/validation/${challengeKey}`;

                    V2Validation.clear();

                    return window.location.assign(validationUrl);
                }

                // For the SMS case with status 403
                if (
                    error?.status === 403 &&
                    error?.data?.expectedEid === 'sms'
                ) {
                    const smsResponse = error?.data;

                    const formattedData: SigningProcessWithOutcome = {
                        customer: smsResponse.customer,
                        caseFile: {
                            language: smsResponse.language,
                            caseFileTypeName: 'Document',
                            title: '',
                            status: 0,
                            sensitiveData: false,
                        },
                        signer: {
                            canAccessMarketingPage: false,
                        },
                    };

                    setCasefileData(formattedData);

                    if (smsResponse.redirect) {
                        setRedirectData({
                            successUrl: smsResponse.redirect.successUrl,
                            failUrl: smsResponse.redirect.failUrl,
                        });
                    }
                } else {
                    setCasefileData(
                        error?.data?.data ?? signingData.get(challengeKey)
                    );

                    if (error?.data?.data?.redirect) {
                        setRedirectData({
                            successUrl: error.data.data.redirect.successUrl,
                            failUrl: error.data.data.redirect.failUrl,
                        });
                    }
                }
            }
        })();
    }, [challengeKey]);

    useEffect(() => {
        if (!casefileData) {
            return;
        }

        const { caseFile } = casefileData;

        (async () => {
            if (!translated) {
                const language = getSignerLanguage() || caseFile.language;

                await TranslationActions.changeLanguageView(language);

                // we need to use the boolean to force re-render after Flux translation
                setTranslated(true);
            }
        })();
    }, [casefileData, translated]);

    useEffect(() => {
        if (
            redirectData?.successUrl === window.location.href ||
            redirectData?.failUrl === window.location.href
        ) {
            return;
        }

        /**
         * We have to check if the success or fail url has the shape
         * of the old V1 signing link structure. Since there are still
         * several of those links out there, we have to make the app
         * backwards compatible.
         */
        if (
            isV1Signing(redirectData?.successUrl) ||
            isV1Signing(redirectData?.failUrl)
        ) {
            return;
        }

        if (
            requestStatus === signingRequestStatus.signed &&
            redirectData?.successUrl
        ) {
            window.location.assign(redirectData.successUrl);
            setShouldRedirect(true);
        } else if (
            requestStatus === signingRequestStatus.rejected &&
            redirectData?.failUrl
        ) {
            window.location.assign(redirectData.failUrl);
            setShouldRedirect(true);
        }
    }, [requestStatus, redirectData]);

    const linkTracking = useCallback(
        (name: string, url: string) =>
            trackSigning(`${page} - Link clicked`, { name, url }),
        [page]
    );

    const linkLocalization = (language: Languages = Languages.EN): string => {
        return LanguagesWithEnglishMarketing.has(language)
            ? ''
            : `${language}/`;
    };

    async function changeLanguage(languageCode: Languages) {
        await TranslationActions.changeLanguageView(languageCode);

        setSignerLanguage(languageCode);

        setUserSelectedLanguage(languageCode);
    }

    if (!casefileData) {
        return (
            <>
                <br />
                <br />
                <Loader />
            </>
        );
    }

    const {
        customer,
        caseFile: { language, caseFileTypeName },
        signer: { canAccessMarketingPage },
    } = casefileData;

    const branding = signingBranding(customer?.branding);

    const links = [
        {
            name: 'Legality of digital signatures',
            url: `blog/digital-signatures/`,
        },
        {
            name: 'Validity of the signed document',
            url: `trust-center`,
        },
        {
            name: 'EU & international regulations',
            url: `blog/eidas-regulation/`,
        },
        {
            name: 'Penneo for businesses',
            url: `free-trial-businesses/`,
        },
    ];

    return translated ? (
        <>
            <Helmet>
                <title>{i18n`signingDone.title`}</title>
            </Helmet>

            <Header
                branding={branding}
                onSelectLanguageClick={changeLanguage}
                showSelectLanguage={true}
            />

            <SigningDoneComponents
                isSigningDoneLeadGen={canAccessMarketingPage}
                requestStatus={requestStatus}
                shouldRedirect={shouldRedirect}
                linkTracking={linkTracking}
                links={links}
                language={UserSelectedLangauge || language}
                branding={branding}
                linkLocalization={linkLocalization}
                caseFileTypeName={caseFileTypeName}
            />
        </>
    ) : null;
};

export default SigningDone;
