import React, { useEffect, useState, useRef } from 'react';

import Constants from 'Constants';
import { TranslationStore } from 'Language';
import { CustomerBranding } from 'types/SigningProcess';
import { trackSigning } from './utils';
import { NewSignerAPI, PublicSignerAPI } from 'Api';
import { Languages } from 'Language/Constants';

import { notify } from 'react-notify-toast';
import { Header } from 'Common/components/HeaderServices/Header';

type Props = {
    currentDocId?: number;
    challengeKey?: string;
    branding?: CustomerBranding;
    isValidatedCasefile?: boolean;
    isAccessControlEnabled?: boolean;
    changeLanguage: (languageCode: Languages) => void;
    finalized?: boolean;
    isSigningDoneLeadGen?: boolean;
};

export const createPdfURL = async (
    isValidatedCasefile,
    isAccessControlEnabled,
    challengeKey,
    currentDocId,
    finalized
) => {
    let url = `/signing-process/${challengeKey}/documents/${currentDocId}/to-be-signed`;

    if (isValidatedCasefile && finalized) {
        url = `/signing-process/${challengeKey}/documents/${currentDocId}/preview`;
    }

    const isCaseFileRestricted = isAccessControlEnabled || isValidatedCasefile;

    if (isCaseFileRestricted && currentDocId) {
        const params =
            (isValidatedCasefile &&
                !finalized && { signed: finalized, decrypt: true }) ||
            null;

        const api = isCaseFileRestricted ? NewSignerAPI : PublicSignerAPI;

        const blob = await api
            .get(url, params, {
                raw: true,
                responseType: 'blob',
            })
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                throw err;
            });

        try {
            const blobUrl = window.URL.createObjectURL(blob.data);

            url = blobUrl;

            return url;
        } catch (error) {
            notify.show(
                'Could not prepare the document for download!',
                'error',
                3000
            );
        }
    }

    return `${Constants.PENNEO_SIGNER_API}/v2${url}`;
};

export const SigningHeader = ({
    currentDocId,
    branding,
    challengeKey,
    isValidatedCasefile,
    isAccessControlEnabled,
    finalized,
    changeLanguage,
}: Props) => {
    const [pdfUrl, setPdfUrl] = useState('#');
    const docId = useRef(currentDocId);

    useEffect(() => {
        if (pdfUrl !== '#' && currentDocId === docId.current) {
            return;
        }

        if (docId && docId.current !== currentDocId) {
            docId.current = currentDocId;
        }

        (async () => {
            const url = await createPdfURL(
                isValidatedCasefile,
                isAccessControlEnabled,
                challengeKey,
                currentDocId,
                finalized
            );

            setPdfUrl(url);
        })();

        return () => {
            if (
                pdfUrl &&
                pdfUrl !== '#' &&
                (pdfUrl as unknown) instanceof Blob
            ) {
                window.URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [challengeKey, currentDocId, isValidatedCasefile, pdfUrl]);

    const currentLanguage: Languages = TranslationStore.getLanguage();

    const showActions = !!challengeKey && !!currentDocId;

    const handleDownloadClick = () => {
        if (showActions) {
            const link = document.createElement('a');

            link.href = pdfUrl;
            link.download = `document-${currentDocId}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            trackSigning('Download PDF button click', {
                documentId: currentDocId,
            });
        }
    };

    const handleOpenClick = () => {
        if (showActions) {
            window.open(pdfUrl, '_blank');

            if (currentDocId) {
                trackSigning('View PDF button click', {
                    documentId: currentDocId,
                });
            }
        }
    };

    const handleHelpClick = () => {
        const helpLinks = {
            da: 'https://www.support.penneo.com/hc/da/articles/20450916545437-Hvordan-underskriver-jeg-med-Penneo',
            fr: 'https://www.support.penneo.com/hc/fr/articles/20450916545437-Comment-signer-un-document',
            nl: 'https://www.support.penneo.com/hc/nl/articles/20450916545437-Hoe-kan-ik-ondertekenen',
        };

        const helpUrl =
            helpLinks[currentLanguage] ||
            'https://www.support.penneo.com/hc/en-gb/articles/20450916545437-How-to-sign-a-document';

        window.open(helpUrl, '_blank');

        trackSigning('Support link click');
    };

    return (
        <Header
            showHelpCenter={true}
            onHelpCenterLinkClick={handleHelpClick}
            showSelectLanguage={true}
            onSelectLanguageClick={changeLanguage}
            showDownloadDocument={showActions}
            onDownloadDocumentClick={handleDownloadClick}
            showOpenDocument={showActions}
            onOpenDocumentClick={handleOpenClick}
            defaultLanguage={currentLanguage}
            branding={branding}></Header>
    );
};
