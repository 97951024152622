import {
    ArrowUpTrayIcon,
    ArrowTopRightOnSquareIcon,
    ChevronLeftIcon,
} from '@heroicons/react/20/solid';
import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import { Button } from '@penneo/vega-ui-react';
import React, { useEffect, useState } from 'react';
import { UtilityState } from 'DocumentServices/Common/Types';
import DocumentFile from './DocumentFile/DocumentFile';
import { Divider } from 'Common/components/Divider/Divider';
import { i18n, translations } from 'Language';
import { useValidator, ValidatorProvider } from './Store/ValidatorContext';
import './Styles/styles.css';
import UILoadingSpinner from 'Common/components/Vega/LoadingSpinner';
import { Languages } from 'Language/Constants';
import { Header } from 'Common/components/HeaderServices/Header';

const Validator = () => {
    return (
        <ValidatorProvider>
            <ValidatorContent />
        </ValidatorProvider>
    );
};

const ValidatorContent = () => {
    const {
        state,
        onFileInputChange,
        activateFileAddHandler,
        reset,
        trackAction,
    } = useValidator();

    const { caseDocument, utilityState } = state;
    const [translationsReady, setTranslationsReady] = useState(false);

    useEffect(() => {
        const storedLang = localStorage.getItem('penneo-language');
        const localLang = storedLang ? JSON.parse(storedLang) : null;

        const lang =
            (new URLSearchParams(window.location.search).get(
                'lang'
            ) as Languages) ??
            (localLang as Languages) ??
            Languages.EN;

        translations.getDictionary(lang, true).then(() => {
            setTranslationsReady(true);
        });
    }, []);

    return (
        <>
            <Header
                showHelpCenter={true}
                onHelpCenterLinkClick={() =>
                    window.open('https://www.support.penneo.com')
                }
            />
            {translationsReady ? (
                <main className="mt-[76px] md:mt-16 sm:px-4 py-8 max-w-[1170px] min-h-[calc(100vh-76px)] md:min-h-[calc(100vh-64px)] mx-auto bg-white max-[1170px]:mx-6">
                    <div className="px-4 py-8 max-w-[904px] mx-auto">
                        <section>
                            <h1 className="text-[30px] font-semibold text-secondary-700">
                                {i18n`validatorTitle`}
                            </h1>
                            <Divider className="my-8" />
                            <p className="text-gray-900 text-base font-normal font-['Open Sans'] leading-normal">
                                {i18n`validatorDescriptionA`}{' '}
                                <span className="font-semibold">
                                    Penneo.com{' '}
                                </span>
                                <a
                                    href={
                                        'https://www.support.penneo.com/hc/en-gb/articles/15579672335516-How-to-check-if-a-document-is-valid'
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => {
                                        trackAction(
                                            'How-to-check-if-a-document-is-valid - clicked'
                                        );
                                    }}
                                    className="inline-block text-secondary-700 font-semibold">
                                    <span className="break-words">
                                        {i18n`validatorPenneoDocumentArticle`}
                                    </span>
                                    <span className="ml-1 no-underline inline-block">
                                        <ArrowTopRightOnSquareIcon className="w-5 h-5 align-text-bottom" />
                                    </span>
                                </a>
                            </p>
                            <p className="mb-8 text-gray-900 text-base font-normal font-['Open Sans'] leading-normal">
                                {i18n`validatorAssistance`}{' '}
                                <a
                                    href={'https://www.support.penneo.com'}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="inline-block text-secondary-700 font-semibold"
                                    onClick={() => {
                                        trackAction(
                                            'contact-support - clicked'
                                        );
                                    }}>
                                    <span className="break-words">{i18n`Contact Support`}</span>
                                    <span className="ml-1 no-underline inline-block">
                                        <ArrowTopRightOnSquareIcon className="w-5 h-5 align-text-bottom" />
                                    </span>
                                </a>
                            </p>
                        </section>
                        <Divider className="my-8" />
                        <section>
                            <div className="mb-8">
                                {!caseDocument ? (
                                    <h2 className="text-2xl text-gray-900 font-semibold leading-loose pt-7">
                                        {i18n`validatorValidateDocument`}
                                    </h2>
                                ) : (
                                    <Button
                                        priority="secondary"
                                        type="neutral"
                                        onClick={reset}
                                        leftIcon={
                                            <ChevronLeftIcon className="w-6 h-6" />
                                        }>
                                        {i18n`validatorValidateAnotherDocument`}
                                    </Button>
                                )}
                            </div>
                            <div className="flex flex-col bg-gray-100 px-4 sm:px-6 pb-8 rounded-md">
                                <div className="inline-flex mb-6">
                                    {utilityState === UtilityState.IDLE && (
                                        <div className="py-2">
                                            <div className="h-10 pt-6 justify-center items-center gap-2 inline-flex">
                                                <DocumentArrowUpIcon className="w-6 h-6 relative overflow-hidden shrink-0" />
                                                <h3 className="text-gray-900 text-lg font-semibold leading-7">
                                                    {i18n`validatorUploadDocument`}
                                                </h3>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {!caseDocument && (
                                    <div className="items-center">
                                        <input
                                            hidden
                                            type="file"
                                            data-testid="fileupload"
                                            id="fileupload"
                                            name="files[]"
                                            className="file w-6 h-6 relative overflow-hidden"
                                            accept="application/pdf"
                                            onChange={onFileInputChange}
                                            onClick={(event) =>
                                                ((
                                                    event.target as HTMLInputElement
                                                ).value = '')
                                            }
                                            multiple={false}
                                        />
                                        <Button
                                            onClick={activateFileAddHandler}
                                            priority="primary"
                                            size="md"
                                            fullWidth={false}
                                            leftIcon={
                                                <ArrowUpTrayIcon className="w-6 h-6" />
                                            }>
                                            {i18n`Upload document`}
                                        </Button>
                                    </div>
                                )}
                                {caseDocument && (
                                    <DocumentFile
                                        fileName={caseDocument.name}
                                        documentState={utilityState}
                                        onReset={reset}
                                        response={state.validatorResponse}
                                    />
                                )}
                                {utilityState !== UtilityState.IDLE && (
                                    <div className="mt-6">
                                        <div className="h-6 justify-center items-center gap-2 inline-flex">
                                            <div className="w-full">
                                                <span className="text-gray-900 text-base">
                                                    {i18n`validatorValidationTime`}
                                                    :{' '}
                                                </span>
                                                <span className="text-gray-600 text-base whitespace-nowrap">
                                                    {state.uploadTime}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="mt-4">
                                <span className="text-gray-900 text-base font-semibold">
                                    {i18n`Please note`}:
                                </span>
                                <span className="text-gray-900 text-base font-normal">
                                    {' '}
                                    {i18n`validatorDisclaimerA`}
                                </span>
                                <span className="text-gray-900 text-base font-semibold">
                                    {' '}
                                    Penneo.com{' '}
                                </span>
                                <span className="text-gray-900 text-base font-normal">
                                    {i18n`validatorDisclaimerB`}
                                </span>
                            </div>
                        </section>
                    </div>
                </main>
            ) : (
                <div className="fixed inset-0 flex items-center justify-center">
                    <UILoadingSpinner variant={'accent'} />
                </div>
            )}
        </>
    );
};

export default Validator;
